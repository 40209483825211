import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {FraudAlertComponentStyled} from './fraudAlertComponentStyled'
import PostCard from './postCard'




const FraudAlertComponent = () => {
    const data = useStaticQuery(graphql`
    query FraudAlertComponentQuery {
        allContentfulPosts(filter: {category: {elemMatch: {name: {eq: "fraud-alert"}}}}, sort: {fields: date, order: DESC}) {
            edges {
              node {
                id
                title
                text {
                  text
                }
                date(formatString: "YYYY-MMM-DD")
                slug
                imageUrl
                category {
                  name
                }
              }
            }
          }
      }

    `)

    if(!data.allContentfulPosts.edges) {
        return <div>Loading...</div>
      } else {
        return (
          <FraudAlertComponentStyled>
            <div className="containerInner">
              {
                data.allContentfulPosts.edges.map((edge) => {
                  return <PostCard node={edge.node} key={edge.node.id} />
                })
              }
            </div>
          </FraudAlertComponentStyled>
        )
      }
}

export default FraudAlertComponent