import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'






export const PostCardStyled = styled.div`
    flex: 50%;
    max-width: 400px;
    margin: 15px 15px 2rem 15px;
    align-self: stretch;
    height: 100%;

    @media ${device.laptop} {
        flex: 1 1;
    }

    .imageWrapper {
        /* animation: bounceIn 2s; */

        img {
            transition: all 0.5s ease 0s;
        }
    }

    .textWrapper {
        margin-top: .6rem;

        .dateWrapper {
            margin-bottom: 1rem;
            font-size: .8rem;
            color: ${palette.ctaLight};
            display: flex;
            align-items: center;

            svg.postCard {
                width: 15px;
                height: 15px;
                margin-right: 10px;

                .group-1 {
                    path, circle {
                        fill: ${palette.ctaLight};
                    }
                }
            }
        }

        .title {
            color: ${palette.cta};
            margin-bottom: .5rem;
            line-height: 1.7rem;
        }
    }

    .buttonWrapper {
        a {
            max-width: unset;
            padding-top: .6rem;
            padding-bottom: .6rem;
        }
    }

    @keyframes bounceIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }
        60% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1);
        }
    }
`;

