import React, {useEffect} from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from "gatsby"
import Hero from '../components/hero'
import FraudAlertPage from '../components/fraudAlertPage'
import BreadCrumb from '../components/breadCrumb'
import FraudAlertComponent from '../components/fraudAlertComponent'




const FraudAlert = () => {
    useEffect(() => {
        let fraudPageStorage = localStorage.getItem("fraudPage");

        if(!fraudPageStorage) {
            localStorage.setItem("fraudPage", true)
        }

        return () => {
        };
    });

    return (
        <Layout hideContact={false} backgroundColor={false} showMarketingPopup={true}>
        <SEO title="Savie Glove Profile company" description={``} />
        <Hero position="Fraud"
            className="heroFraud"
        />
        <BreadCrumb className="fraudAlert" breadCrumbTitle="Fraud & Infringement" />
        <FraudAlertComponent />
    </Layout>
    )

}

export default FraudAlert
