import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'




export const FraudAlertComponentStyled = styled.div`
    padding: 50px 0 80px 0;
    max-width: ${width.xxl};
    margin: 0 auto;

    .containerInner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        animation: bounce-in-right 1s;
    }

    @keyframes bounceIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }
        60% {
            transform: scale(1.2);

        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes bounce-in-right {
        0% {
            opacity: 0;
            transform: translateX(2000px);
        }
        60% {

            transform: translateX(-30px);
        }
        80% {
            transform: translateX(10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;
