import React from "react"
import Img from 'gatsby-image'
import PropTypes from 'prop-types'




const Images = ({images, slug}) => {
    return images.map((image) => {
        return (
            <Img key={image.id} alt={slug} sizes={image.sizes} className={slug} placeholderStyle={{ visibility: "hidden" }} />
        )
    })
}

Images.propTypes = {
    images: PropTypes.array.isRequired,
    slug: PropTypes.string.isRequired,
}

 export default Images

