import React from 'react'
import Markdown from 'react-markdown'
import {PostCardStyled} from './postCardStyled'
import PropTypes from 'prop-types'
import emptyImage from '../images/image-symbol.jpg'
import ButtonCta from './buttonCta'
import DateIcon from './icons/dateIcon'





const PostCard = ({node}) => {

    return <PostCardStyled>
                <div className="imageWrapper">
                    {node.imageUrl ? <img src={node.imageUrl} className="postImage" alt={node.title} /> :
                    <img src={emptyImage} className="postImage" alt={'No Image'} />}
                </div>
                <div className="textWrapper">
                    {node.date && <div className="dateWrapper"><DateIcon name="postCard"/>{node.date}</div>}
                    {node.title && <h2 className="title">
                        {node.title}
                    </h2>}
                    {node.text.text && <div className="detail">
                        <Markdown source={node.text.text} />
                    </div>}
                </div>
                <div className="buttonWrapper">
                    <ButtonCta text="More detail" className="more-detail" linkTo={`/${node.slug ? `${node.slug}/` : ''}`} isCta={true} />
                </div>
        </PostCardStyled>
}

PostCard.propTypes = {
}


export default PostCard