import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import Images from './images'



const ImageGroup = ({slug, className}) => {
const data = useStaticQuery(graphql`
    query ImagesQuery {
      allContentfulImages {
          edges {
            node {
              image {
                file {
                  url
                    fileName
                }
                sizes(quality: 90, maxHeight: 1000, resizingBehavior: PAD) {
                    ...GatsbyContentfulSizes_withWebp
                    sizes
                    src
                    srcSet
                    base64
                }
                id
              }
              slug
              title
              id
            }
          }
        }
      }
    `)
  if(!data.allContentfulImages.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulImages.edges.map((edge) => {
      if(edge.node && edge.node.slug && (edge.node.slug === slug) ) {
          return (
            <div key={edge.node.id} className={`imagesWrapper ${slug}`}>
              <div className="innerWrap">
                <Images images={edge.node.image} slug={slug} />
              </div>
            </div>
          )
      }
      return true;
    })
  }
}

ImageGroup.propTypes = {
    slug: PropTypes.string.isRequired
}

export default ImageGroup
