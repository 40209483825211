import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import {TitleWithTextStyled} from './titleWithTextStyled'




export const FraudAlertPageStyled = styled.div`
    padding: 50px 20px 80px 20px;
    display: flex;

    .contentWrap {
        width: ${width.xxl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${device.desktop} {
            flex-direction: row;
        }
    }

    .mainWrap {

        .imagesWrapper.fake-1 {
            .innerWrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 0;
                padding-right: 0;

                @media ${device.tablet} {
                    padding: 15px;
                }

                .gatsby-image-wrapper {
                    width: 100%;
                    max-width: 100%;

                    @media ${device.tablet} {
                        max-width: 400px;
                    }


                    &:not(:first-child) {
                        margin-top: 1rem;
                    }
                }
            }

        }

        .imagesWrapper.savie-box {
            .innerWrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media ${device.tablet} {
                    flex-direction: row;
                }

                .gatsby-image-wrapper {
                    width: 100%;
                    max-width: 100%;

                    @media ${device.tablet} {
                        max-width: 400px;
                    }
                }

            }

        }
    }

`;

export const FraudPageTitleInnerStyle = styled(TitleWithTextStyled)`

    .fraud-info, .not-savie, .yes-savie {

        .title {
            text-transform: none;
            text-align: left;
            display: block;
            margin-bottom: 1rem;

            @media ${device.tablet} {
                text-align: center;
            }
        }

        p {
            text-align: left;

            @media ${device.tablet} {
                text-align: center;
            }
        }
    }

    .fraud-info {
        margin-bottom: 4rem;

        .container {
            p strong {
                color: ${palette.redDanger};
            }
            p em strong {
                color: ${palette.cta};
                font-style: normal;
            }

            .title {
                font-weight: 700;
            }
        }
    }

    .not-savie {
        .title {
            color: ${palette.redDanger};
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            text-transform: uppercase;

            @media ${device.tablet} {
                line-height: 3.5rem;
                font-size: 2rem;
            }
        }
    }

    .yes-savie {
        .title {
            color: ${palette.cta};
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            margin-top: 4rem;
            text-transform: uppercase;

            @media ${device.tablet} {
                line-height: 3.5rem;
                font-size: 2rem;
            }
        }
    }


`;
